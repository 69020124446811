<!--
 * @Description: 用户菜单
 * @Author: zhang zhen
 * @Date: 2023-02-10 11:35:56
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-05-19 18:59:32
 * @FilePath: /page-sass/src/components/tools/menus.vue
-->
<template>
  <div>
    <a-dropdown placement="bottomRight" @visibleChange="handleInitState">
      <div class="cover" :class="!showEntry && 'hiddenLeft'">
        <a-avatar class="avatar" :src="setUserInfo().avatar || '/avatar.png'" />
        <slot></slot>
      </div>
      <div class="user-dropdown-menu-wrapper" slot="overlay">
        <div class="menus-cover">
          <!-- 用户信息 -->
          <div class="userInfo">
            <img :src="setUserInfo().avatar || require('@/assets/newHomePage/bg-avatar.png')" alt="" class="avatar-big">
            <div class="info-right">
              <span class="username">{{ setUserInfo().nickname || '无用户名' }} </span>
              <div class="authentic" v-if="!Authentication">
                <span class="label">帐号未认证</span><a href="/businessManagement/companyInfo" class="action">立即认证
                ></a>
              </div>
            </div>
          </div>
          <div class="quickly-entry">
            <!-- :class="!showEntry && 'm-t-20'" -->
            <div class="tradeIdentity">
              <div class="tradeIdentity-content">
                <span class="role-info">{{ roleType == 'BUSINESS' ? '企业' : '个人'
                  }}·{{ business?.roleName }}</span>
                <span class="memo">
                  <span class="spc">查看更多详情</span>
                  <a href="/workSpace" class="quick_link">进入工作台 ></a>
                </span>
              </div>
              <img src="/litter-card.png" alt="" class="icon">
            </div>
            <!-- <div class="moreAction">
              <div class="left-info">
                <span class="title-top">私有化包装采购</span>
                <img src="/menu-title.png" alt="" class="desc">
              </div>
              <router-link tag="div" to="/PublishingRequirements?formType=product" class="action-btn"></router-link>
            </div> -->
          </div>
        </div>
        <div class="block"></div>
        <!--  -->
        <div class="action-item" @click="handleNavigatePage" v-if="business?.roleType === BusinessRoleType.PURCHASER">
          <img src="/sent_new.png" alt="" class="icon">
          <span>发布需求</span>
        </div>
        <router-link tag="div" to="/userInfo/basicUserInfo" class="action-item">
          <img src="/account_new.png" alt="" class="icon">
          <span>账号管理</span>
        </router-link>
        <!-- 菜单区域 -->
        <router-link tag="div" to="/requirement/requirementList" class="action-item"
                     v-if="business?.roleType === BusinessRoleType.PURCHASER">
          <img src="/requirement_new.png" alt="" class="icon">
          <span>我的需求</span>
        </router-link>
        <!-- orderManagement -->
        <router-link tag="div" to="/orderManagement" class="action-item">
          <img src="/order_new.png" alt="" class="icon">
          <span>我的订单</span>
        </router-link>
        <router-link @mouseover.native="hover=true" @mouseleave.native="hover=false" tag="div"
                     class="action-item" style="position: static" to="">
          <img src="/switch_new.png" alt="" class="icon">
          <span>切换身份</span>
          <div v-show="hover" class="hover-box">
            <a-radio-group style="width: 100%" v-model="userRoleType">
              <div class="action-item action-card-part"
                   style="margin-bottom: 8px;width: 100%" v-for="item in roleList"
                   @click="onSelectRole(item.roleType	)">
                <div style="display: flex;align-items: center">
                  <img src="@/assets/newHomePage/bg-avatar.png" alt="" class="icon">
                  <div style="display: flex;flex-direction: column">
                    <span class="user-nick-name">{{ item.roleName }}</span>
                    <!--                    <span class="user-role">{{ item.roleName }}</span>-->
                  </div>
                </div>
                <a-radio name="roleType" :value="item.roleType"></a-radio>
              </div>
            </a-radio-group>
          </div>

        </router-link>
        <div class="block more-margin"></div>
        <div class="action-item" @click="handleLoginOut">
          <img src="/action_3_new.png" alt="" class="icon">
          <span>退出登录</span>
        </div>
      </div>
    </a-dropdown>
    <ChangePassword ref="ChangePassword" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ChangePassword from './changePassword.vue'
import { getAction, postAction } from '@/api/manage.js'
import sendMethods from '@/mixins/sendMethods.js'
import { setCurBusiness } from '@/util/utils'
import { BusinessRoleType } from '@/util/constant'

export default {
  name: 'userMenus',
  computed: {
    BusinessRoleType() {
      return BusinessRoleType
    }
  },
  mixins: [sendMethods],
  components: {
    ChangePassword
  },
  props: {
    showEntry: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      userInfo: {},
      roleType: 'BUSINESS',
      tradeIdentity: '0',
      Authentication: false,
      hover: false,
      value: '',
      roleList: [],
      userRoleType: '1',
      business: {}
    }
  },
  created() {
    const { tradeIdentity, roleType } = this.setUserInfo()
    this.roleType = roleType
    this.tradeIdentity = tradeIdentity
  },
  methods: {
    ...mapActions(['loginOut']),
    ...mapGetters(['setUserInfo']),
    handleInitState(e) {
      if (!e) return
      this.getUserToken()
    },
    getBusinessRole(businessId) {
      getAction('/v2/business/basic/info/queryByBusinessId', {
        businessId
      }).then(res => {
        const { success, data } = res
        if (success) {
          const { roleList } = data
          this.roleList = roleList
        }
      })
    },
    handleLoginOut() {
      const that = this
      this.$confirm({
        title: '提示',
        content: '真的要退出登录吗 ?',
        onOk() {
          that.loginOut().then(res => {
            that.$router.push({ path: '/' })
          })
        }
      })
    }, onSelectRole(roleType) {
      postAction('/user/switchBusiness', {
        roleType
      }).then(res => {
        const { success, data } = res
        if (success) {
          this.getUserToken(() => {
            window.location.reload()
          })
          this.$message.success('切换身份成功')
        }
      })
    },
    getUserToken(callback) {
      let token = ''
      const authInfo = localStorage.getItem('authInfo')
      if (authInfo) {
        const {
          Authorization
        } = JSON.parse(authInfo)
        token = Authorization // 让每个请求携带自定义 token 请根据实际情况自行修改
      }
      if (!token) {
        return
      }
      getAction('/user/token/info', {
        token
      }).then(res => {
        const { success, data } = res
        if (success) {
          const { businessDto } = data
          this.Authentication = !!businessDto
          this.business = businessDto
          setCurBusiness(businessDto)
          this.userRoleType = businessDto?.roleType
          this.getBusinessRole(businessDto?.businessId)
          if (callback) {
            callback()
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.avatar {
  cursor: pointer;
  width: 36px;
  height: 36px;
  background-color: transparent;
  // margin: 0 30px;
  // margin-left: 10px;
}

.noLeft {
  .hiddenLeft {
    margin-left: 0 !important;
  }
}

.cover {
  display: flex;
  align-items: center;
  margin-left: 24px;

  &.hiddenLeft {
    margin-left: 12px;
  }
}

.user-dropdown-menu-wrapper {
  width: 280px;
  padding: 15px 0 6px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 6px;
  margin-top: 13px;
  box-shadow: 0px 4px 16px 0px #00000029;
  // box-shadow: 0px 0px 2px 0px #00000014;
  .userInfo {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .avatar-big {
    width: 32px;
    height: 32px;
    margin-right: 6px;
    border-radius: 50%;
  }

  .username {
    color: #262626;
    font-weight: 500;
  }

  .quickly-entry {
    background-color: #F4F5F7;
    border-radius: 4px;
    position: relative;
    margin-bottom: 8px;
    // height: 132px;
    .tradeIdentity {
      height: 63px;
      border-radius: 6px;
      background: #99A3BA;
      padding: 0 15px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      color: #fff;
      position: relative;
      font-size: 14px;
      font-weight: 600;

      .icon {
        width: 16px;
        height: 16px;
        position: absolute;
        right: 6px;
      }

      &-content {
        display: flex;
        flex-direction: column;

        .memo {
          margin-top: 6px;
          display: flex;
          align-items: center;

          span.spc {
            font-size: 12px;
            color: #FFFFFF99;
            font-weight: 400;
          }

          a.quick_link {
            font-size: 12px;
            color: #fff;
            font-weight: 400;
            margin-left: 8px;
          }
        }
      }
    }

    .moreAction {
      display: flex;
      height: 69px;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      box-sizing: border-box;

      .left-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        grid-gap: 5px 5px;

        .title-top {
          font-family: PingFang SC;
          font-size: 13px;
          font-weight: 500;
          line-height: 18px;
          text-align: left;
          color: #232529;
        }

        .desc {
          width: 130px;
        }
      }

      .action-btn {
        width: 56px;
        height: 20px;
        background: url('/send-btn.png') no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
    }

    .bg {
      width: 71px;
      height: 54px;
      position: absolute;
      right: 2px;
      bottom: 0;
    }
  }

  .menus-cover {
    padding: 0 15px;
    box-sizing: border-box;
  }

  .action-item {
    cursor: pointer;
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    color: #6D7075;
    font-size: 12px;
    font-weight: 500;

    .icon {
      //width: 24px;
      //height: 24px;
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }

    &:hover {
      background-color: #FFF2EA;

      //.role-items{
      //  display: none;
      //}
    }
  }

  .action-card-part {
    justify-content: space-between;
    padding: 8px 16px;
    height: 49px;
    border: 1px solid #EFEFEF;
    border-radius: 4px;
    align-items: center;
  }

  .user-nick-name {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #131212;
  }

  .user-role {

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #8F949D;
    padding-top: 2px;
  }

  .cut-role-action-item:hover {
    .role-items {
      display: block;
    }
  }
}

.m-t-20 {
  margin-top: 10px;
}

.block {
  background: #EFEFEF;
  width: calc(100% - 30px);
  height: 1px;
  margin: 0 auto 8px;
}

.authentic {
  display: flex;
  align-items: center;
  grid-gap: 0 8px;
  margin-top: 4px;

  .label {
    color: #8F949D;
    font-size: 12px;
  }

  .action {
    font-size: 12px;
  }
}

.more-margin {
  margin: 10px 0;
}

.hover-box {
  position: absolute;
  left: -200px;
  width: 200px;
  padding: 8px;
  background: #FFFFFF;
  /* 二级向下 shadow_md */
  //box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 4px 16px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08);

  border-radius: 3px;
}
</style>
