<!--
 * @Description: 基础布局
 * @Author: zhang zhen
 * @Date: 2023-02-03 09:13:01
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-12-02 14:10:05
 * @FilePath: /page-sass/src/components/layouts/basicLayout.vue
-->
<template>
  <a-layout>
    <GlobalHeader/>
    <a-layout-content>
        <router-view />
    </a-layout-content>
    <GLobalFooter/>
    <ChoosePackagingMaterial />
  </a-layout>
</template>

<script>
import { GlobalHeader, GLobalFooter } from "../page";
import ChoosePackagingMaterial from "../plugins/ChoosePackagingMaterial.vue";
export default {
  name: "basicLayout",
  components: {
    GlobalHeader,
    GLobalFooter,
    ChoosePackagingMaterial
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.ant-layout {
  min-height: 100%;
  background-color: #F7F8FA;
  // height: 100%;
}
.ant-layout-content {
  width: 100%;
  min-height: calc(100vh - 464px);
}
</style>
