/*
 * @Description: 菜单权限控制
 * @Author: zhang zhen
 * @Date: 2023-02-10 13:56:33
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-02-14 11:52:23
 * @FilePath: /page-sass/src/permission.js
 */
import router from './router'
import store from './store'

router.beforeEach((to, from, next) => {
  let isLogin = store.getters.isLogin; // 执行
  // console.log(to, )
  const { meta, path } = to
  // let isLogin = userStatus
  // console.log(isLogin)
  //   debugger
  const redirect = decodeURIComponent(from.query.redirect || path)
  if (meta.needLogin) {
    if (!isLogin) {
      // 未登录
      next({ path: '/login', query: { redirect: to.fullPath } })
    } else {
    //   console.warn(from.query.redirect, path)
      // 已经登录
      //   if (redirect == path) {
      //     next() // 替换地址
      //   } else {
      //     next(redirect)
      //   }
      next()
    }
  } else {
    // 不需要登录的页面
    next()
    // if (redirect == path) {
    //   console.log('不需要登录的页面2', redirect, path)
    //   next()
    // } else {
    //   console.log('不需要登录的页面1', redirect, path)
    //   next(redirect)
    // }
  }
})
