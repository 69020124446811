<!--
 * @Description: 一般布局的模板
 * @Author: zhang zhen
 * @Date: 2023-06-13 11:34:32
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-04-25 17:05:16
 * @FilePath: /page-sass/src/components/layouts/normalLayout.vue
-->
<template>
  <a-layout>
    <NormalHeader/>
    <a-layout-content>
      <router-view />
    </a-layout-content>
    <!-- <GLobalFooter /> -->
    <!-- <ChoosePackagingMaterial /> -->
  </a-layout>
</template>

<script>
import { GLobalFooter, NormalHeader } from '../page'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'normalLayout',
  components: {
    GLobalFooter,
    NormalHeader,
  },
  data() {
    return {}
  },
  created () {
    // this.setUserInfo().businessId && this.emitMessageList();
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    ...mapActions(['emitMessageList']),

  },
}
</script>

<style lang="less" scoped>
.ant-layout {
  min-height: 100%;
  background-color: #F7F8FA;
  // height: 100%;
}
.ant-layout-content {
  width: 100%;
  min-height: calc(100vh - 464px);
}
</style>
