<!--
 * @Description: 修改密码
 * @Author: zhang zhen
 * @Date: 2023-02-11 11:31:04
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-03-16 09:59:11
 * @FilePath: /page-sass/src/components/tools/changePassword.vue
-->
<template>
  <a-modal
    title="修改密码"
    :visible="visible"
    :confirmLoading="loading"
    @cancel="handleCancel"
    @ok="handleOk"
    centered
    :destroyOnClose="true"
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      class="formView"
      :rules="rules"
    >
      <a-form-model-item label="旧密码" prop="oldPassword">
        <a-input type="password" v-model="form.oldPassword" placeholder="请输入旧密码" />
      </a-form-model-item>
      <a-form-model-item label="新密码" prop="password">
        <a-input type="password" v-model="form.password" placeholder="请输入新密码" />
      </a-form-model-item>
      <a-form-model-item label="确认新密码" prop="newPassword">
        <a-input type="password" v-model="form.newPassword" placeholder="请输入确认新密码" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { postQueryAction } from '@/api/manage'
import { mapGetters } from 'vuex'
export default {
  name: 'changePassword',
  data() {
    const validateToNextPassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入新密码'))
      } else {
        if (this.form.newPassword !== '') {
          this.$refs.ruleForm.validateField('newPassword')
        }
        callback()
      }
    }
    const checkNewPassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入确认新密码'))
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不匹配!'))
      } else {
        callback()
      }
    }
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      visible: false,
      loading: false,
      form: {},
      rules: {
        oldPassword: [
          {
            required: true,
            message: '请输入旧密码!'
          },
          { required: true, min: 8, max: 20, message: '请输入8-20位密码' }
        ],
        password: {
          required: true,
          validator: validateToNextPassword
        },
        newPassword: {
          required: true,
          validator: checkNewPassword
        }
      }
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleOpen() {
      this.visible = true
    },
    handleOk() {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          let formData = Object.assign({}, this.form)
          formData.password && delete formData.password
          this.loading = true
          postQueryAction('/juno-users/password', {
            ...formData,
            // userId: this.setUserInfo().userId
          }).then(res => {
            const { code, message, data } = res
            this.loading = false
            if (code == '200') {
              this.$message.success('密码修改成功')
              this.handleCancel()
            } else {
              this.$message.warning(message)
            }
          }).catch(err => {
            this.loading = false 
          })
        } else {
          return false
        }
      })
    },
    handleCancel() {
      this.loading = false
      this.visible = false
      this.form = {}
    }
  }
}
</script>

<style lang="less" scoped></style>
