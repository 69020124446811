<!--
 * @Description: 黑色背景的头部
 * @Author: zhang zhen
 * @Date: 2023-06-13 11:32:21
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-05-22 18:09:53
 * @FilePath: /page-sass/src/components/page/normalHeader.vue
-->
<template>
  <div class="header-cover">
    <div class="normal-header">
      <img src="~@/assets/whiteLogo.png" alt="" class="logo-white" />
      <div class="userInfo">
        <!-- <router-link tag="div" to="/" class="navigation-name" style="cursor: pointer;">
        <img src="~@/assets/newHomePage/backUp.png" alt="" class="backUp">
      </router-link> -->
        <template v-if="hasLogin">
          <router-link tag="div" to="/" class="link-text">返回首页</router-link>
          <router-link tag="div" to="/workSpace" class="link-text">工作台</router-link>
          <router-link tag="div" to="/messageManagement/messageList" class="nav-link">
            <a-popover placement="bottomRight" :autoAdjustOverflow="true" :arrowPointAtCenter="false"
              overlayClassName="header-notice-wrapper" :overlayStyle="{ width: '364px', top: '50px' }">
              <template slot="content">
                <div class="header-title">
                  <img src="/notice_icon.png" alt="" class="alarm">
                  <span>消息中心</span>
                </div>
                <div class="notice-content-item">
                  <span class="left-label">
                    <span class="label">系统消息</span>
                    <a-badge :count="countInfo.msgCategory_1 || 0" :overflow-count="999"
                      :number-style="{ backgroundColor: '#EE4261' }" v-if="countInfo.msgCategory_1" />
                    <a-badge v-else :showZero="true" count="0" :number-style="{
          backgroundColor: '#EFEFEF',
          color: 'rgba(0, 0, 0, .25)',
          boxShadow: '0 0 0 1px #d9d9d9 inset',
        }" />
                  </span>
                  <img src="/showInfo.png" alt="" class="showInfo" @click="handleJump(1)">
                </div>
                <div class="notice-content-item">
                  <span class="left-label">
                    <span class="label">服务消息</span>
                    <a-badge :count="countInfo.msgCategory_2 || 0" :overflow-count="999"
                      :number-style="{ backgroundColor: '#EE4261' }" v-if="countInfo.msgCategory_2" />
                    <a-badge v-else :showZero="true" count="0" :number-style="{
          backgroundColor: '#EFEFEF',
          color: 'rgba(0, 0, 0, .25)',
          boxShadow: '0 0 0 1px #d9d9d9 inset',
        }" />
                  </span>
                  <img src="/showInfo.png" alt="" class="showInfo" @click="handleJump(2)">
                </div>
                <div class="notice-content-item">
                  <span class="left-label">
                    <span class="label">客户消息</span>
                    <a-badge :count="countInfo.msgCategory_3 || 0" :overflow-count="999"
                      :number-style="{ backgroundColor: '#EE4261' }" v-if="countInfo.msgCategory_3" />
                    <a-badge v-else :showZero="true" count="0" :number-style="{
          backgroundColor: '#EFEFEF',
          color: 'rgba(0, 0, 0, .25)',
          boxShadow: '0 0 0 1px #d9d9d9 inset',
        }" />
                  </span>
                  <img src="/showInfo.png" alt="" class="showInfo" @click="handleJumpChat">
                </div>
              </template>
              <a-badge :count="messageCount" :number-style="{ backgroundColor: '#EE4261' }" :overflow-count="999">
                <div class="link-text">消息</div>
              </a-badge>
            </a-popover>
          </router-link>
          <Menus :showEntry="false" class="noLeft" />
        </template>
        <router-link tag="span" to="/login" class="font-15" v-else>你尚未登录，请点击登录</router-link>
      </div>
    </div>
  </div>

</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Menus from '@/components/tools/menus.vue'
import Notice from '@/mixins/notice'

export default {
  name: 'normalHeader',
  mixins: [Notice],
  components: {
    Menus,
  },
  data() {
    return {
    }
  },
  watch: {
    $route(to, form, next) {
      this.isLogin()
    }
  },
  computed: {
    ...mapState({
      hasLogin: state => state.user.isLogin,
      messageList: (state) => state.messageList, // 消息列表
      messageCount: (state) => state.messageCount, // 平台信息
    })
  },
  methods: {
    ...mapGetters(['isLogin']),
    ...mapActions(['deleteMessageList']),
    handleJump(msgCategory) {
      this.$router.push({
        path: '/messageManagement/messageList',
        query: {
          msgCategory
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.header-cover {
  background-color: #333;
}

.normal-header {
  // width: calc(100% - 256px);
  // max-width: 1184px;
  width: 1184px;
  max-width: 1184px;
  z-index: 24;
  height: 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 0 28px 0 128px;
  box-sizing: border-box;
  margin: 0 auto;

  img.logo-white {
    width: 90px;
  }

  .userInfo {
    color: #fff;
    display: flex;
    align-items: center;
    grid-gap: 0 32px;

    .link-text {
      cursor: pointer;
    }

    span.line {
      margin: 0 13px;
      color: #E3E3E3;
    }

    .m-l-8 {
      margin-left: 8px;
      cursor: pointer;
    }
  }
}

.backUp {
  width: 16px;
}

::v-deep .ant-badge-multiple-words {
  padding: 0 4px;
  right: -7px;
}

::v-deep .ant-badge-count {
  padding: 0 6px;
  right: -12px;
  top: -3px;
}
</style>
