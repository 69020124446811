export const BusinessRoleType = {
  SUPPLIER: '0', // 供应商
  PURCHASER: '2', // 采购商
  DESIGNER: '4', // 设计师
  FACILITATOR: '1' // 服务商
}

export const IdType = {
  BUSINESS: 'BUSINESS', // 供应商
  PERSON: 'PERSON', // 采购商
}
