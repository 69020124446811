<!--
 * @Description: 头部搜索区域
 * @Author: zhang zhen
 * @Date: 2023-02-13 15:44:39
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-04-25 09:16:19
 * @FilePath: /page-sass/src/components/tools/searchCenter.vue
-->
<template>
  <div class="searchCenter">
    <a-input-search placeholder="搜索供应商/方案/产品" size="large" class="searchInput" allowClear
      ><a-select default-value="" slot="addonBefore" style="width: 78px;">
        <a-icon type="caret-down" slot="suffixIcon" />
        <a-select-option value="">
          全部
        </a-select-option>
        <a-select-option value="1">
          供应商
        </a-select-option>
        <a-select-option value="2">
          服务商
        </a-select-option>
      </a-select>
    </a-input-search>
    <a-button type="primary" size="large" @click="handleOpenRequire">发布需求</a-button>
    <a-button type="primary" size="large">入住接单</a-button>
    <FormNavigateModal ref="FormNavigateModal" />
  </div>
</template>

<script>
import FormNavigateModal from './formNavigateModal.vue'
export default {
  name: 'searchCenter',
  components: {
    FormNavigateModal
  },
  data() {
    return {}
  },
  methods: {
    /* 跳转需求 */
    handleOpenRequire() {
      this.$refs.FormNavigateModal.handleOpenView()
    }
  }
}
</script>

<style lang="less" scoped>
.searchCenter {
  display: flex;
  align-items: center;
   
  .ant-btn {
    margin-left: 8px;
  }
}
</style>
