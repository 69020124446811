/*
 * @Description: 主入口
 * @Version: 2.0
 * @Author: xiaoyaozi
 * @Date: 2020-06-28 13:38:34
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-05-18 23:29:49
 */ 
import Vue from 'vue'
import Vuex from 'vuex'
import user from  './user'
import { getMessageCount } from '@/api/api'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    messageList: [], // 消息列表
    messageCount: 0, // 消息数量 
    showChoosePackagingMaterial: false, // 选择包装材料
    packagingMaterial: null, // 包装材料
    countInfo: { // 消息的统计
      msgCategory_1: 0,
      msgCategory_2: 0,
      msgCategory_3: 0
    },
    showChat: false, // 聊天框
    chatInfo: null,
  },
  mutations: {
    setMessageList(state, item) {
        state.messageList.push(item) // 消息列表
        state.messageCount = state.messageCount + 1;
    },
    switchChat(state, info) {
      state.showChat = !state.showChat;
      state.chatInfo = info || null;
    },
    changeMessageNumber(state, info) {
      const { params, total } = info
        state.messageCount = total;
        state.countInfo = params;
    },
    DeleteItem(state, id) {
        state.messageList = state.messageList.filter(i => i.bizOrderNo != id)
        state.messageCount = state.messageList.length;
    },
    changeChooseModalStatus(state, status) {
      state.showChoosePackagingMaterial = status
    },
    changePackagingMaterial(state, material) {
      state.packagingMaterial = material
    }
  },
  getters:{
  
  },
  actions: {
    emitMessageList({ commit }, data) {
      commit('setMessageList', data)
    },
    deleteMessageList({ commit }, id) {
      commit('DeleteItem', id)
    },
    asyncChangeStatus({ commit }, status) {
      commit('changeChooseModalStatus', status)
    },
    asyncChangePackagingMaterial({ commit }, value) {
      commit('changePackagingMaterial', value)
    },
    dispatchChangeMessageNumber({ commit }) {
      let params = { msgCategory_1: 0, msgCategory_2: 0, msgCategory_3: 0 }
      getMessageCount().then(res => {
        const { success, data } = res
        if (success && data.length) {
          // 后端提供的是一个数组对象， 我需要通过msgCategory来判断是什么值， 获取到对应的值
          // this.countInfo['msgCategory_1'] = data.unreadCount || 0
          // msgCategory: 1.系统消息， 2.服务消息
          data.map(i => {
            const { unreadCount, msgCategory } = i
            params[`msgCategory_${msgCategory}`] = unreadCount || 0
          })
          const { msgCategory_1, msgCategory_2, msgCategory_3 } = params;
          commit('changeMessageNumber',{ params, total:  msgCategory_1 + msgCategory_2 + msgCategory_3 }); // 更新角标的数组
        }
      }) 
    },
    asyncChat({ commit }, info) {
      commit('switchChat', info)
    }
  },
  modules: {
   user
  }
})
