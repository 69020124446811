<!--
 * @Description: 页面面包屑导航
 * @Author: zhang zhen
 * @Date: 2023-06-15 09:27:24
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-11-20 17:21:37
 * @FilePath: /page-sass/src/components/page/pageBreadcrumb.vue
-->
<template>
  <div class="pageBreadcrumb">
    <a-breadcrumb :routes="routes" separator=">">
      <template slot="itemRender" slot-scope="{ route, params, routes, paths }">
        <span v-if="routes.indexOf(route) === routes.length - 1">
          {{ route.meta.title }}
        </span>
        <router-link v-else :to="route.path">
          {{ route.meta.title }}
        </router-link>
      </template>
    </a-breadcrumb>
  </div>
</template>

<script>
import { BasicRoutes } from '@/router'
import { mapGetters } from 'vuex'
import { filterRoutesByUserIdentity, createBreadcrumb } from '@/util/utils'
export default {
  name: 'PageBreadcrumb',
  data() {
    return {}
  },
  computed: {
    routes() {
      const copyRoutes = [...BasicRoutes]
      let newRoutes = [],
        judgeKeys = [];
        const { tradeIdentity, businessId } = this.setUserInfo();
      if (tradeIdentity != 0) {
        // 0供应商看需求池
        judgeKeys = ['requirementPool', 'quotePriceManagement', 'invitedMe']
      }
      !businessId && (judgeKeys = [...judgeKeys, 'EmployeeManagement', 'positionManagement', 'RolePermissions'])
      copyRoutes.map((i) => {
        filterRoutesByUserIdentity(i, judgeKeys)
        newRoutes.push(i)
      })
      // 1. 获取当前路由
      const { path } = this.$route
      let pathArr = path.split('/').filter((i) => i != '')
      return createBreadcrumb(newRoutes, pathArr, path)
    },
  },
  methods: {
    ...mapGetters(['setUserInfo']),
  },
}
</script>

<style lang="less" scoped>
.pageBreadcrumb {
  height: 68px;
  width: 100%;
  background: #fff;
  // border-bottom: 1px solid #EFEFEF;
  padding: 15px 40px 15px 32px;
  position: relative;
  display: flex;
  align-items: center;
  &::before {
    display: block;
    content: '';
    width: calc(100% - 72px);
    height: 1px;
    background-color: #e3e3e3;
    position: absolute;
    left: 32px;
    bottom: 0;
  }
  ::v-deep .ant-breadcrumb a {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
  }
  ::v-deep .ant-breadcrumb span:not(.ant-breadcrumb-separator) {
    color: rgba(0, 0, 0, 0.85);
    font-size: 12px;
  }
}
</style>
