<!--
 * @Description: 路由控制模板页面
 * @Author: zhang zhen
 * @Date: 2023-02-13 17:04:08
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-04-26 22:47:05
 * @FilePath: /page-sass/src/components/layouts/routeView.vue
-->

<template>
  <div class="main" style="padding: 0">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'RouteView',

}
</script>
