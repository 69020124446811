/*
 * @Description:过滤器
 * @Version: 2.0
 * @Autor: xiaoyaozi
 * @Date: 2020-07-20 13:52:47
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-03-22 15:43:06
 */
import dayjs from 'dayjs'

/* 日期格式过滤 */
const formatDate = (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') => {
  return dayjs(dataStr).format(pattern)
}

/** 字符串超长截取省略号显示 */
const ellipsis = (value, vlength = 25) => {
  if (!value) {
    return ''
  }
  if (value.length > vlength) {
    return value.slice(0, vlength) + '...'
  }
  return value
}
/**
 * @description: 统一单位换算
 * @param {*} value
 * @return {*}
 */
const CreateUnit = (value) => {
  if (!value) {
    return '-'
  }
  if (value < 10000) {
    return value + "元";
  } else if (value < 1000000) {
    return (value / 10000).toFixed(2) + "万";
  } else if (value < 10000000) {
    return  (value / 1000000).toFixed(2) + "百万";
  } else if (value < 100000000) {
    return (value / 10000000).toFixed(2) + "千万";
  } else {
    return (value / 100000000).toFixed(2) + "亿";
  }
}
export default {
  formatDate,
  ellipsis,
  CreateUnit
}
