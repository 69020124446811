<!--
 * @Description: 尾部组件
 * @Author: zhang zhen
 * @Date: 2023-02-03 09:07:41
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-07-19 17:56:13
 * @FilePath: /page-sass/src/components/page/GlobalFooter.vue
-->
<template>
  <a-layout-footer>
    <section class="ant-layout-footer-top">
      <img src="~@/assets/whiteLogoNew.png" alt class="logo" />
      <a-row :gutter="24" type="flex">
        <a-col :lg="3" :md="8" :sm="12" :xs="24" class="links-container">
          <div class="links-container-item">关于我们</div>
          <a class="links-container-item" href="/" target="_blank">业务范畴</a>
          <a class="links-container-item" href="/" target="_blank">合作联系</a>
          <a class="links-container-item" href="/" target="_blank">办公地点</a>
          <a class="links-container-item" href="/" target="_blank">公司信息</a>
          <a class="links-container-item" href="/" target="_blank">公司资质</a>
        </a-col>
        <a-col :lg="3" :md="8" :sm="12" :xs="24" class="links-container">
          <div class="links-container-item">平台保障</div>
          <a class="links-container-item" href="/" target="_blank">流程规范</a>
          <a class="links-container-item" href="/" target="_blank">服务标准</a>
          <a class="links-container-item" href="/" target="_blank">资金安全</a>
          <a class="links-container-item" href="/" target="_blank">风险控制</a>
        </a-col>
        <a-col :lg="3" :md="8" :sm="12" :xs="24" class="links-container">
          <div class="links-container-item">服务支持</div>
          <a class="links-container-item" href="/" target="_blank">新人引入</a>
        </a-col>
        <a-col :lg="3" :md="8" :sm="12" :xs="24" class="links-container">
          <div class="links-container-item">规则反馈</div>
          <a class="links-container-item" href="/" target="_blank">隐私保护政策</a>
        </a-col>

        <a-col :lg="3" :md="8" :sm="12" :xs="24" class="links-container">
          <div class="links-container-item">更多推荐</div>
          <a class="links-container-item" href="/" target="_blank">包装app</a>
          <a class="links-container-item" href="/" target="_blank">额外服务</a>
        </a-col>
        <a-col :lg="3" :md="8" :sm="12" :xs="24" class="links-container">
          <div class="links-container-item">官方客服</div>
          <a class="links-container-item" href="/" target="_blank">400-900-900</a>
        </a-col>
        <a-col :lg="6" :md="8" :sm="12" :xs="24" class="links-container">
          <div class="cover">
            <div class="large-title">关注xxx</div>
            <img src="~@/assets/code.jpg" alt="平台二维码" class="qrCode" />
          </div>
        </a-col>
      </a-row>
    </section>
    <section class="ant-layout-footer-bottom">
      <div class="footer-wrap">
        <div style="margin:0 auto; padding:20px 0;">
          <a
            target="_blank"
            href=" "
            style="display:inline-block;text-decoration:none;"
          >
            <img src="~@/assets/2691689763798_.pic.jpg" style="float:left;"/>
            <p
              style="float:left;margin: 0px 0px 0px 5px; color:#939393;"
            >浙公网安备 33049802000338号</p>
          </a>
          <a href="https://beian.miit.gov.cn" target="_blank" style="float:right;margin: 0px 0px 0px 5px; color:#939393;">
          Copyright ©上海岭湃科技有限公司 版权所有 沪ICP备2023010173号-1
          </a>
        </div>
      </div>
    </section>
  </a-layout-footer>
</template>

<script>
export default {
  name: 'GlobalFooter',
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
* {
  padding: 0;
  margin: 0;
  list-style: none;
}
.ant-layout-footer {
  background: #10131a;
  color: #fff;
  padding: 40px 78px 42px 77px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-top {
    display: flex;
    align-items: center;
    width: 1288px;
    .logo {
      height: 76px;
      margin-right: 85px;
    }
    .ant-row-flex {
      flex: 1 0 0;
    }
    .links-container {
      // padding-top: 20px;
      &-item {
        color: #fff;
        font-size: 12px;
        display: block;
        text-align: center;
        line-height: 19px;
        font-weight: 500;
        text-align: left;
        margin-bottom: 16px;
        &:first-child {
          font-size: 16px;
          font-weight: 500;
          line-height: 26px;
        }
      }
      &:last-of-type {
        .flexLayout(@direction: column, @justifyContent: flex-start);
      }
    }
    .cover {
      width: max-content;
    }
    .large-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      margin-bottom: 16px;
      text-align: left;
    }
    ::v-deep.qrCode {
      width: 110px;
      height: auto !important;
      border-radius: 4px;
    }
  }
  &-bottom {
    margin-top: 34px;
    padding: 15px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    width: 1288px;
    .footer-wrap {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      span {
        font-weight: 500;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.7);
      }
    }
    .footer-wrap-center {
      display: inline-block;
      text-align: center;
      margin: 0 64px;
    }
  }
}

.ant-layout-footer{
  padding-bottom: 30px!important;
}
</style>
