<!--
 * @Description: 用户菜单
 * @Author: zhang zhen
 * @Date: 2023-02-10 11:35:56
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-02-27 20:23:06
 * @FilePath: /page-sass/src/components/tools/userMenus.vue
-->
<template>
  <div>
    <a-dropdown placement="bottomCenter">
      <div class="cover">
        <a-avatar class="avatar" :src="setUserInfo().avatar || (iconType == 'dark'? '/dark.png' : 'light.png')" />
        <slot></slot>
      </div>

      <a-menu slot="overlay" class="user-dropdown-menu-wrapper">
        <a-menu-item
          key="1"
          style="text-align: center; color: #262626!important;font-weight: 500;!important;"
          >注册信息</a-menu-item
        >
        <a-menu-item
          key="2"
          @click="handleChangePassword"
          style="text-align: center; color: #262626!important;font-weight: 500;!important"
          >修改密码</a-menu-item
        >
        <a-menu-divider />
        <a-menu-item
          key="3"
          @click="handleLoginOut"
          style="text-align: center; color: #262626!important;font-weight: 500;!important"
          >退出登录</a-menu-item
        >
      </a-menu>
    </a-dropdown>
    <ChangePassword ref="ChangePassword" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ChangePassword from './changePassword.vue'
export default {
  name: 'userMenus',
  components: {
    ChangePassword
  },
  props: {
    iconType: {
      type: String,
      default: 'light'
    }
  },
  data() {
    return {
      userInfo: {}
    }
  },
  methods: {
    ...mapActions(['loginOut']),
    ...mapGetters(['setUserInfo']),
    handleChangePassword() {
      this.$refs.ChangePassword.handleOpen()
    },
    handleLoginOut() {
      const that = this
      this.$confirm({
        title: '提示',
        content: '真的要退出登录吗 ?',
        centered: true,
        onOk() {
          that.loginOut().then(res => {
            that.$router.push({ path: '/login' })
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.avatar {
  cursor: pointer;
  width: 18px;
  height: 18px;
  background-color: transparent;
  // margin: 0 30px;
  // margin-left: 10px;
}
.cover {
  display: flex;
  align-items: center;
}
</style>
