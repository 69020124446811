/*
 * @Description: 公用接口封装
 * @Author: zhang zhen
 * @Date: 2023-03-06 17:01:28
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-04-26 22:21:53
 * @FilePath: /page-sass/src/api/api.js
 */
import { getAction, postAction } from './manage'

export const getUserInfo = (userId) => getAction('/info', { userId }); // 获取用户信息
export const getMessageCount = () => getAction('/remind/msg/countQuery'); // 获取消息统计
export const getRemindInfo = () => getAction('/new/data/query/remind');
