<!--
 * @Description: 管理页面模板
 * @Author: zhang zhen
 * @Date: 2023-02-13 16:00:43
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-24 13:52:29
 * @FilePath: /page-sass/src/components/layouts/layOutView.vue
-->
<template>
  <a-layout>
    <!-- <GlobalHeader class="mainHeader" :needSearchBar="true"/> -->
    <a-layout class="main-content backEnd" :class="collapsed ? 'full' : 'less'">
      <LayoutAside ref="LayoutAside" />
      <a-layout-content>
        <div class="cover" :class="collapsed ? 'full' : 'less'">
          <WorkSpaceHeader @changeMenus="handleShowMenus" />
          <!-- <PageBreadcrumb /> -->
        </div>
        <div class="inline-block"></div>
        <div class="main">
          <router-view />
        </div>
      </a-layout-content>
    </a-layout>
    <!-- 聊天相关模块 -->
    <!-- <ChatView /> -->
  </a-layout>
</template>

<script>
import { WorkSpaceHeader, GLobalFooter } from '../page'
import LayoutAside from '../menu/LayoutAside.vue'
import { mapGetters, mapActions } from 'vuex'
import messageJumper from '@/mixins/messageJumper.js'
// import ChatView from '@/components/chatView/index.vue'
export default {
  name: 'LayOutView',
  mixins: [messageJumper],
  components: {
    WorkSpaceHeader,
    GLobalFooter,
    LayoutAside
  },
  data() {
    return {
      collapsed: false,
      websock: null,
    }
  },
  created() {
    this.setUserInfo().businessId && this.initWebSocket()
  },
  destroyed() {
    // this.handleCleanLooper()
    // 离开页面生命周期函数
    if (this.websock) {
      this.websock.close()
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    ...mapActions(['emitMessageList', 'deleteMessageList', 'dispatchChangeMessageNumber']),
    /* 控制子组件菜单状态 */
    handleShowMenus(e) {
      this.collapsed = e
      this.$refs.LayoutAside.changeCollapsed(e)
    },
    initWebSocket: function () {
      var businessId = this.setUserInfo().businessId
      this.websock = new WebSocket(`${process.env.VUE_APP_SOCKET_URL}/websocket/notification/${businessId}`)
      this.websock.onopen = this.websocketOnopen
      this.websock.onmessage = this.websocketOnmessage
    },
    websocketOnopen: function () {
      console.log('WebSocket连接成功')
      //心跳检测重置
      //this.heartCheck.reset().start();
    },
    websocketOnerror: function (e) {
      console.log('WebSocket连接发生错误')
      this.reconnect()
    },
    websocketOnmessage: function (e) {
      const key = `open${Date.now()}`
      let info = eval('(' + e.data + ')')
      const { description, linkDesc, dateAck, bizTypeLabel } = info;
      console.log({ description, linkDesc, dateAck }, 1222)
      // this.emitMessageList(info)
      this.dispatchChangeMessageNumber()
      this.$notification.open({
        duration: null,
        message: (h) => {
          return h(
            'div',
            {
              style: {
                color: '#FF6026',
                fontSize: '16px',
                fontWeight: 500,
                lineHieght: '24px'
              },
            },
            bizTypeLabel
          )
        },
        description: (h) =>
          h('div', { class: 'message-content' }, [
              h('div', { class: 'message-cover' }, [
                h('div', { class: 'text-center'}, description)
                // h('div', { class: 'text-center'}, `${messageDesc || '-'}`),
                // h('div', { class: 'text-info label-right'}, '】，请尽快处理!'),
              ]),
              h(
                'a',
                {
                  class: 'more-action-area',
                  on: {
                    click: () => {
                      this.$notification.close(key)
                      this.handleJumpInfo(info)
                    },
                  },
                },
                [
                  h('span', { class: 'more-text-right'}, linkDesc),
                  h('img', { class: 'more-text-right-icon', attrs: { src: '/icon-right.png'} })
                ]
              ),
            ]
          ),
        style: {
          width: '476px',
          minHeight: '110px',
          borderRadius: '4px',
          padding: '24px',
          // marginRight: `25px`,
          boxShadow: ' 0px 0px 30px 0px #0000001A',
        },

        top: '76px',
        key,
        class: 'message-notification',
        icon: (h) =>
          h('img', { attrs: { src: '/message.png' }, style: { width: '24px', height: '24px', marginBottom: '4px' } }),
      })
    },
  },
}
</script>

<style lang="less" scoped>
.mainHeader {
  position: sticky;
  top: 0;
  z-index: 999;
}

.ant-layout {
  min-height: 100%;
  background: transparent;
  // height: 100%;
}

.main-content {
  &.full {
    padding-left: 80px;
    box-sizing: border-box;
  }

  &.less {
    padding-left: 228px;
    box-sizing: border-box;
  }
}

.ant-layout-content {
  width: 100%;
  min-height: calc(100vh - 132px);
  background: #F7F8FA;
  .main {
    padding: 14px 24px 32px 12px;
  }
}

.inline-block {
  height: 64px;
}

.cover {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 205;
  border-left: 1px solid rgba(0, 0, 0, 0.006);
  box-shadow: 0px 1px 0px 0px #0000000A;
  // position: relative;
  // &::after {
  //   display: block;
  //   content: '';
  //   height: 64px;
  //   width: 1px;
  //   position: absolute;
  //   right: 1px;
  //   top: 0;
  //   background: #0000000A;
  // }
  &.full {
    width: calc(100% - 80px);
  }

  &.less {
    width: calc(100% - 228px);
  }
}</style>
